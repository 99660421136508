import {
  RESET_STATE,
  SET_EVENT_TYPE,
  FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  SET_PAGE_NUMBER,
  SET_LOADING,
  RESET_EVENTS,
  UPDATE_SECONDARY_MARKETS,
  DISABLE_EVENT_DATA,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_BINARY_MARKETS,
} from './exchangeSportsActionTypes';

import {
  CompetitionEventTypeMap,
  ExchangeSportsState,
} from '../../models/ExchangeSportsState';
import { EXCHANGE_EVENT_TYPES } from '../../constants/ExchangeEventTypes';
import { CompetitionDTO } from '../../models/common/CompetitionDTO';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';
import { ExchangePriceDTO } from '../../models/common/ExchangePriceDTO';
import { WORLD_CUP } from '../../constants/IPLEvent';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExchangeSportsState = {
  eventTypes: EXCHANGE_EVENT_TYPES,
  selectedEventType: { id: '', name: '', slug: '' },
  fetchingEvents: false,
  pageNumebr: 1,
  pageSize: 20,
  competitions: {},
  selectedCompetition: { id: '', name: '', slug: '' },
  events: {},
  selectedEvent: { id: '', name: '', slug: '' },
  secondaryMatchOddsMap: {},
  secondaryMarketsMap: {},
  scorecard: null,
  binaryMarkets: [],
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  for (let i = 0; i < 3; i += 1) {
    if (prices[i]) pricesSet.push(prices[i]);
    else pricesSet.push({ price: null, size: null });
  }
  return pricesSet;
};

const getFancyCategory = (runnerName: string) => {
  // if (runnerName.toLowerCase().includes('ball run')) {
  //   return 'ball-by-ball';
  // } else if (runnerName.toLowerCase().includes('over run')) {
  //   return 'over-by-over';
  // } else {
  //   return 'session-market';
  // }
  return 'session-market';
};

const exchangeSportsReducer = (
  state = initialState,
  action: Action
): ExchangeSportsState => {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...state,
        eventTypes: [],
        selectedEventType: { id: '1', name: 'Football', slug: 'football' },
        competitions: {},
        selectedCompetition: { id: '', name: '', slug: '' },
        events: {},
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        fetchingEvents: action.payload,
      };
    }

    case SET_EVENT_TYPE: {
      return {
        ...state,
        selectedEventType: action.payload,
        pageNumebr: 1,
      };
    }

    case SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumebr: action.payload,
      };
    }

    case FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS: {
      let competitions: CompetitionDTO[] = [];
      let allCompetitions: CompetitionEventTypeMap = { ...state.competitions };
      const eId = action.payload.sportId;
      for (let c of action.payload.competitions) {
        const nameSlug = c.competitionName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        competitions.push({
          id: c.competitionId,
          name: c.competitionName,
          slug: nameSlug,
          sportId: eId,
        });
      }
      allCompetitions[eId] = competitions;
      return {
        ...state,
        competitions: allCompetitions,
      };
    }

    case SET_COMPETITION: {
      return {
        ...state,
        selectedCompetition: action.payload,
        pageNumebr: 1,
      };
    }

    case FETCH_EVENT_BY_COMPETITION_SUCCESS: {
      const eId: string = action.payload.sportId;
      const cId: string = action.payload.competitionId;
      const eventId: string = action.payload.eventId
        ? action.payload.eventId
        : action.payload.eventData.eventId;

      // Add events map hierarchy
      const allEvents = { ...state.events };
      if (!allEvents[eId]) {
        allEvents[eId] = {};
      }
      if (!allEvents[eId][cId]) {
        allEvents[eId][cId] = {};
      }

      // Add events data
      if (action.payload.eventData) {
        let eData;
        if (eventId && allEvents[eId][cId][eventId])
          eData = { ...allEvents[eId][cId][eventId] };
        else eData = { ...action.payload.eventData };

        if (eData && eData.eventId && eData.eventId !== 'undefined') {
          let homeTeam = eData.homeTeam ? eData.homeTeam : '';
          let awayTeam = eData.awayTeam ? eData.awayTeam : '';
          if (
            homeTeam === '' &&
            awayTeam === '' &&
            (eData.eventName?.toLowerCase().includes(' v ') ||
              eData.eventName?.toLowerCase().includes(' vs '))
          ) {
            homeTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[0].trim()
              : eData.eventName.includes(' VS ')
              ? eData.eventName.split(' VS ')[0].trim()
              : eData.eventName.split(' Vs ')[0].trim();
            awayTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[1].trim().split(' - ')[0]
              : eData.eventName.includes(' VS ')
              ? eData.eventName.split(' VS ')[1].trim().split(' - ')[0]
              : eData.eventName.split(' Vs ')[1].trim().split(' - ')[0];
          }
          // Set MatchOdds Data.
          const matchOddsData = action.payload.matchOddsData;
          const runners: MatchOddsRunnerDTO[] = [];
          if (matchOddsData) {
            if (
              matchOddsData.matchOddsRunners &&
              matchOddsData.matchOddsRunners.length > 0
            ) {
              let i = 0;
              for (let e of matchOddsData.matchOddsRunners) {
                if (e) {
                  let runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                  if (runnerName === undefined) {
                    runnerName = '';
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i === 0) {
                    if (runnerName === '') {
                      runnerName = homeTeam;
                    } else if (runnerName !== awayTeam) {
                      homeTeam = runnerName;
                    }
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i !== 0) {
                    if (runnerName === '') {
                      runnerName = awayTeam;
                    } else if (runnerName !== homeTeam) {
                      awayTeam = runnerName;
                    }
                  }

                  i += 1;
                  runners.push({
                    selectionId: e.selectionId ? e.selectionId : e.SelectionId,
                    runnerName: runnerName,
                    backPrices: getMatchOddsSet(e.backPrices),
                    layPrices: getMatchOddsSet(e.layPrices),
                    status: e.status,
                  });
                }
              }
            }
            eData.matchOdds = {
              marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
              marketName: matchOddsData.marketName
                ? matchOddsData.marketName
                : '',
              inplay: matchOddsData.inplay
                ? matchOddsData.inplay
                : eData.inPlay,
              status: matchOddsData.status ? matchOddsData.status : '',
              matchOddsRunners: runners,
            };
          } else {
            let matchOdds = eData.matchOdds;
            if (matchOdds) {
              eData.matchOdds = {
                marketId: matchOdds.marketId,
                marketName: matchOdds.marketName,
                inplay: matchOdds.inplay,
                status: 'SUSPENDED',
                matchOddsRunners: matchOdds.matchOddsRunners,
              };
            } else {
              eData.matchOdds = {
                marketId: '',
                marketName: '',
                inplay: eData.inPlay,
                status: 'SUSPENDED',
                matchOddsRunners: [],
              };
            }
          }

          // Set EventData
          eData.homeTeam = homeTeam;
          eData.awayTeam = awayTeam;
          eData.eventSlug = eData.eventSlug
            ? eData.eventSlug
            : eData.eventName
            ? eData.eventName
                .toLowerCase()
                .replace(/[^a-z0-9]/g, ' ')
                .replace(/ +/g, ' ')
                .trim()
                .split(' ')
                .join('-')
            : '';

          allEvents[eId][cId][eData.eventId] = eData;
        }
      }
      if (action.payload.onRefresh) {
        let currentSelectedCompetition;
        let currentSelectedEvent;
        let currentSelectedEventType;

        try {
          currentSelectedEventType =
            state.selectedEventType.id === eId
              ? { ...state.selectedEventType }
              : {
                  id: eId,
                  name: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                    .name,
                  slug: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                    .slug,
                };
          currentSelectedCompetition =
            state.selectedCompetition.id === cId
              ? { ...state.selectedCompetition }
              : {
                  id: action.payload.eventData?.competitionId
                    ? action.payload.eventData.competitionId
                    : '',
                  name: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                    : '',
                  slug: action.payload.eventData?.competitionName
                    ? action.payload.eventData.competitionName
                        .toLocaleLowerCase()
                        .replace(/[^a-z0-9]/g, ' ')
                        .replace(/ +/g, ' ')
                        .trim()
                        .split(' ')
                        .join('-')
                    : '',
                };

          currentSelectedEvent =
            state.selectedEvent.id === action.payload.eventData?.eventId
              ? { ...state.selectedEvent }
              : {
                  id: action.payload.eventData?.eventId,
                  slug: action.payload.eventData?.eventName
                    .toLocaleLowerCase()
                    .replace(/[^a-z0-9]/g, ' ')
                    .replace(/ +/g, ' ')
                    .trim()
                    .split(' ')
                    .join('-'),
                  name: action.payload.eventData?.eventName,
                };
        } catch (_) {
          currentSelectedCompetition = { ...state.selectedCompetition };
          currentSelectedEvent = { ...state.selectedEvent };
          currentSelectedEventType = { ...state.selectedEventType };
        }
        return {
          ...state,
          events: allEvents,
          selectedEventType: currentSelectedEventType,
          selectedCompetition: currentSelectedCompetition,
          selectedEvent: currentSelectedEvent,
        };
      }
      return {
        ...state,
        events: allEvents,
      };
    }

    case UPDATE_SECONDARY_MATCH_ODDS: {
      const eventId: string = action.payload.eventId;
      const marketId: string = action.payload.marketId;

      const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };

      const matchOddsData = action.payload.matchOddsData;
      const runners: MatchOddsRunnerDTO[] = [];
      if (matchOddsData) {
        if (
          matchOddsData.matchOddsRunners &&
          matchOddsData.matchOddsRunners.length > 0
        ) {
          for (let e of matchOddsData.matchOddsRunners) {
            if (e) {
              if (
                eventId !== WORLD_CUP.eventId ||
                eventId === WORLD_CUP.eventId
              ) {
                const runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                runners.push({
                  selectionId: e.selectionId ? e.selectionId : e.SelectionId,
                  runnerName: runnerName,
                  backPrices: getMatchOddsSet(e.backPrices),
                  layPrices: getMatchOddsSet(e.layPrices),
                  status: e.status,
                });
              }
            }
          }
        }
        allSecMatchOddsMap[eventId + '-' + marketId] = {
          marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
          marketName: matchOddsData.marketName ? matchOddsData.marketName : '',
          inplay: matchOddsData.inplay ? matchOddsData.inplay : false,
          status: matchOddsData.status ? matchOddsData.status : '',
          matchOddsRunners: runners,
        };
      } else {
        let matchOdds = allSecMatchOddsMap[eventId + '-' + marketId];
        if (matchOdds) {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            inplay: matchOdds.inplay,
            status: 'SUSPENDED',
            matchOddsRunners: matchOdds.matchOddsRunners,
          };
        } else {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: '',
            marketName: '',
            inplay: false,
            status: 'SUSPENDED',
            matchOddsRunners: [],
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMatchOddsMap };
      secMatchOddsMap[eventId + '-' + marketId] =
        allSecMatchOddsMap[eventId + '-' + marketId];

      return {
        ...state,
        secondaryMatchOddsMap: secMatchOddsMap,
      };
    }

    case UPDATE_SECONDARY_MARKETS: {
      const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            bookmakers: [],
            enableBookmaker: false,
            fancyMarkets: [],
            enableFancy: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData = action.payload.bookmakerOddsData;
        let bookMakerOdds: BookmakerDTO[] = [];
        if (bookMakerOddsData && bookMakerOddsData.length > 0) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            for (let b of br.bookmakerRunners) {
              bmRunners.push({
                selectionId: b.selectionId ? b.selectionId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrice,
                backSize: b.backSize,
                layPrice: b.layPrice,
                laySize: b.laySize,
              });
            }
            bookMakerOdds.push({
              marketId: br.marketId ? br.marketId : '-1',
              marketName: br.marketName ? br.marketName : 'Bookmaker',
              bookmakerRunners: bmRunners,
              status: br.status ? br.status : 'OPEN',
            });
          }
        } else if (marketsMap[eventId].bookmakers[0]) {
          bookMakerOdds = marketsMap[eventId].bookmakers;
          for (let bm of bookMakerOdds) {
            for (let br of bm.bookmakerRunners) {
              br.backPrice = '0';
              br.layPrice = '0';
            }
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[eventId].bookmakers = bookMakerOdds;
        marketsMap[eventId].enableBookmaker = action.payload.enableBookmaker;
        // Set Fancy markets data
        const fancyOddsData = action.payload.sessionOddsData;
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            if (!f.runnerName.toLowerCase().split(' ').includes('bhav')) {
              fancyOdds.push({
                selectionId: f.selectionId ? f.selectionId : '',
                runnerName: f.runnerName ? f.runnerName : '',
                gameStatus: f.gameStatus ? f.gameStatus : '',
                layPrice: f.layPrice
                  ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                    ? f.layPrice.toFixed(2)
                    : f.layPrice
                  : null,
                backPrice: f.backPrice
                  ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                    ? f.backPrice.toFixed(2)
                    : f.backPrice
                  : null,
                laySize: f.laySize ? f.laySize : null,
                backSize: f.backSize ? f.backSize : null,
                category: getFancyCategory(f.runnerName),
              });
            }
          }
        } else if (marketsMap[eventId].fancyMarkets) {
          fancyOdds = marketsMap[eventId].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.gameStatus = 'SUSPENDED';
          }
        }
        // fancyOdds.sort((a, b) => {
        //   const aDesc = a.selectionId;
        //   const bDesc = b.selectionId;
        //   if (aDesc > bDesc) return -1;
        //   else if (aDesc < bDesc) return 1;
        //   else return 0;
        // });
        marketsMap[eventId].fancyMarkets = fancyOdds;
        marketsMap[eventId].enableFancy = action.payload.enableFancy;
      }

      return {
        ...state,
        secondaryMarketsMap: marketsMap,
      };
    }

    case DISABLE_EVENT_DATA: {
      const sportId = action.payload.sportId;
      const competitionId = action.payload.competitionId;
      const eventId = action.payload.eventId;
      let disableEvent = action.payload.disableEvent;
      if (!disableEvent && state.selectedEvent.id === eventId) {
        disableEvent = true;
      }

      const allEvents = { ...state.events };
      const allSecMarkets = { ...state.secondaryMarketsMap };

      if (allEvents[sportId][competitionId][eventId])
        if (disableEvent) {
          const eData = allEvents[sportId][competitionId][eventId];
          for (let runner of eData.matchOdds?.matchOddsRunners) {
            runner.status = 'SUSPENDED';
          }
          allEvents[sportId][competitionId][eventId] = eData;
        } else {
          delete allEvents[sportId][competitionId][eventId];
        }
      if (allSecMarkets[eventId])
        if (disableEvent) {
          const secMarkets = allSecMarkets[eventId];
          for (let bookmaker of secMarkets?.bookmakers) {
            for (let runner of bookmaker.bookmakerRunners) {
              runner.backPrice = '0';
              runner.backSize = '0';
              runner.layPrice = '0';
              runner.laySize = '0';
            }
          }
          for (let fanctMarket of secMarkets?.fancyMarkets) {
            fanctMarket.gameStatus = 'SUSPENDED';
          }
          allSecMarkets[eventId] = secMarkets;
        } else {
          delete allSecMarkets[eventId];
        }

      return {
        ...state,
        events: allEvents,
        secondaryMarketsMap: allSecMarkets,
      };
    }

    case SET_EXCH_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }

    case RESET_EVENTS: {
      return {
        ...state,
        events: {},
      };
    }

    case UPDATE_SCORECARD: {
      return {
        ...state,
        scorecard: action.payload,
      };
    }

    case UPDATE_BINARY_MARKETS: {
      console.log(action.payload);
      return {
        ...state,
        binaryMarkets: action.payload,
      };
    }
    default:
      return state;
  }
};

export default exchangeSportsReducer;
