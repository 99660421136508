export const WORLD_CUP = {
  sportId: '1',
  competitionId: '12469077',
  eventId: '31345701',
  routeHash: `1:12469077:31345701`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};

export const BBL_MEN = {
  sportId: '4',
  competitionId: '10328858',
  eventId: '27996014',
  routeHash: `4:10328858:27996014`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};

export const PSL_CUP = {
  sportId: '4',
  competitionId: '10693181',
  eventId: '28102621',
  routeHash: `4:10693181:28102621`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};

export const AU_OPEN_WOMENS = {
  sportId: '2',
  competitionId: '12309951',
  eventId: '30299977',
  routeHash: `2:12309951:30299977`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};

export const IPL_CUP = {
  sportId: '4',
  competitionId: '101480',
  eventId: '28127348',
  routeHash: `4:101480:28127348`,
};
