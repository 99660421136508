import {
  RESET_STATE,
  SET_EVENT_TYPE,
  FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  SET_PAGE_NUMBER,
  SET_LOADING,
  RESET_EVENTS,
  UPDATE_SECONDARY_MARKETS,
  DISABLE_EVENT_DATA,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_BINARY_MARKETS,
} from './exchangeSportsActionTypes';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { AxiosResponse } from 'axios';
import API from '../../api';
import { EventDTO } from '../../models/common/EventDTO';
import { EXCH_SPORTS_MAP } from '../../constants/ExchangeEventTypes';

// redux Actions
export const resetExchangeState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

// EventTypes
export const setEventType = (eventType: SelectedObj) => {
  return {
    type: SET_EVENT_TYPE,
    payload: eventType,
  };
};

export const setPageNumber = (pageNo: number) => {
  return {
    type: SET_PAGE_NUMBER,
    payload: pageNo,
  };
};

const setLoading = (laoding: boolean) => {
  return {
    type: SET_LOADING,
    payload: laoding,
  };
};

// Competitions
export const fetchCompetitions = (sportId: string) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '') {
        const result = await API.get(
          `/common-feed/list-competitions/${sportId}`
        );
        if (result.data) {
          const payload = {
            sportId: sportId,
            competitions: result.data,
          };
          dispatch(fetchCompetitionsSuccess(payload));
        } else {
          const payload = {
            sportId: sportId,
            competitions: [],
          };
          dispatch(fetchCompetitionsSuccess(payload));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchCompetitionsSuccess = (result) => {
  return {
    type: FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
    payload: result,
  };
};

export const setCompetition = (competition: SelectedObj) => {
  return {
    type: SET_COMPETITION,
    payload: competition,
  };
};

// Events
export const fetchEventsByCompetition = (
  sportId: string,
  competitionId: string,
  events: EventDTO[]
) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '' && competitionId !== '') {
        dispatch(setLoading(true));
        let result = null;
        if (sessionStorage.getItem('jwt_token')) {
          result = await API.get(
            `/common-feed/list-events/${sportId}/${competitionId}`,
            {
              headers: {
                Authorization: sessionStorage.getItem('jwt_token'),
              },
            }
          );
        } else {
          result = await API.get(
            `/common-feed/list-events/${sportId}/${competitionId}`
          );
        }

        let newList = [];
        if (result && result.data.length > 0) {
          for (let eventData of result.data) {
            if (eventData.eventId) {
              newList.push(eventData.eventId);
              const eData: EventDTO = {
                inPlay: eventData.inPlay,
                openDate: eventData.openDate,
                sportId: sportId,
                competitionId: eventData.competitionId,
                competitionName: eventData.competitionName
                  ? eventData.competitionName
                  : 'Other',
                eventId: eventData.eventId,
                eventName: eventData.eventName,
                marketId: eventData.marketId,
                providerName: eventData.providerName,
              };
              const payload = {
                eventData: eData,
                sportId: sportId,
                competitionId: eData.competitionId,
                matchOddsData: eventData.market
                  ? eventData.market.matchOdds.find(
                      (mo) => mo.marketName === 'Match Odds'
                    )
                  : null,
              };
              dispatch(fetchEventByCompetitionSuccess(payload));
              // if (eData.sportId === '1' || eData.sportId === '4') {
              for (let mo of eventData.market.matchOdds) {
                if (mo.marketName !== 'Match Odds') {
                  const secMOPayload = {
                    eventId: eData.eventId,
                    marketId: mo.marketId,
                    matchOddsData: mo,
                  };
                  dispatch(updateSecondaryMatchOdds(secMOPayload));
                }
              }
              // }
              if (eData.sportId === '4') {
                const secMarketsPayload = {
                  eventId: eData.eventId,
                  bookmakerOddsData: eventData.market
                    ? eventData.market.bookmakers
                    : null,
                  enableBookmaker: eventData.market
                    ? eventData.market.enableBookmaker
                    : false,
                  sessionOddsData: eventData.market
                    ? eventData.market.fancyMarkets
                    : null,
                  enableFancy: eventData.market
                    ? eventData.market.enableFancy
                    : false,
                };
                dispatch(updateSecondaryMarkets(secMarketsPayload));
              }
            }
          }
          if (events && events.length > 0) {
            for (let ie of events) {
              if (!newList.includes(ie.eventId)) {
                const payload = {
                  sportId: ie.sportId,
                  competitionId: ie.competitionId,
                  eventId: ie.eventId,
                  disableEvent: false,
                };
                dispatch(disableEventData(payload));
              }
            }
          }
        } else {
          for (let ie of events) {
            const payload = {
              sportId: ie.sportId,
              competitionId: ie.competitionId,
              eventId: ie.eventId,
              disableEvent: true,
            };
            dispatch(disableEventData(payload));
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
};

export const fetchEventsBySport = (sportId: string, events: EventDTO[]) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '') {
        dispatch(setLoading(true));
        let result = null;
        if (sessionStorage.getItem('jwt_token')) {
          result = await API.get(`/common-feed/list-events/${sportId}`, {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
          });
        } else {
          result = await API.get(`/common-feed/list-events/${sportId}`);
        }

        let newList = [];
        if (result && result.data.length > 0) {
          for (let eventData of result.data) {
            if (eventData.eventId) {
              newList.push(eventData.eventId);
              const eData: EventDTO = {
                inPlay: eventData.inPlay,
                openDate: eventData.openDate,
                sportId: sportId,
                competitionId: eventData.competitionId,
                competitionName: eventData.competitionName
                  ? eventData.competitionName
                  : 'Other',
                eventId: eventData.eventId,
                eventName: eventData.eventName,
                marketId: eventData.marketId,
                providerName: eventData.providerName,
              };
              const payload = {
                eventData: eData,
                sportId: sportId,
                competitionId: eData.competitionId,
                matchOddsData:
                  eventData.market && eventData.market.matchOdds
                    ? eventData.market.matchOdds.find(
                        (mo) => mo.marketName === 'Match Odds'
                      )
                    : null,
              };
              dispatch(fetchEventByCompetitionSuccess(payload));
              // if (eData.sportId === '1' || eData.sportId === '4') {
              for (let mo of eventData.market.matchOdds) {
                if (mo.marketName !== 'Match Odds') {
                  const secMOPayload = {
                    eventId: eData.eventId,
                    marketId: mo.marketId,
                    matchOddsData: mo,
                  };
                  dispatch(updateSecondaryMatchOdds(secMOPayload));
                }
              }
              // }
              if (eData.sportId === '4') {
                const secMarketsPayload = {
                  eventId: eData.eventId,
                  bookmakerOddsData: eventData.market
                    ? eventData.market.bookmakers
                    : null,
                  enableBookmaker: eventData.market
                    ? eventData.market.enableBookmaker
                    : false,
                  sessionOddsData: eventData.market
                    ? eventData.market.fancyMarkets
                    : null,
                  enableFancy: eventData.market
                    ? eventData.market.enableFancy
                    : false,
                };
                dispatch(updateSecondaryMarkets(secMarketsPayload));
              }
            }
          }
          if (events && events.length > 0) {
            for (let ie of events) {
              if (!newList.includes(ie.eventId)) {
                const payload = {
                  sportId: ie.sportId,
                  competitionId: ie.competitionId,
                  eventId: ie.eventId,
                  disableEvent: false,
                };
                dispatch(disableEventData(payload));
              }
            }
          }
        } else {
          if (events) {
            for (let ie of events) {
              const payload = {
                sportId: ie.sportId,
                competitionId: ie.competitionId,
                eventId: ie.eventId,
                disableEvent: false,
              };
              dispatch(disableEventData(payload));
            }
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
};

export const fetchInplayEvents = (currentInplayEvents: EventDTO[]) => {
  return async (dispatch: Function) => {
    try {
      dispatch(setLoading(true));
      let result = null;
      if (sessionStorage.getItem('jwt_token')) {
        result = await API.get(`/common-feed/list-inplay-events`, {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        });
      } else {
        result = await API.get(`/common-feed/list-inplay-events`);
      }

      let newList = [];
      if (result && result.data.length > 0) {
        for (let eventData of result.data) {
          if (eventData.eventId) {
            newList.push(eventData.eventId);
            const eData: EventDTO = {
              inPlay: eventData.inPlay,
              openDate: eventData.openDate,
              sportId: EXCH_SPORTS_MAP[eventData.sportId],
              competitionId: eventData.competitionId,
              competitionName: eventData.competitionName
                ? eventData.competitionName
                : 'Other',
              eventId: eventData.eventId,
              eventName: eventData.eventName,
              marketId: eventData.marketId,
              providerName: eventData.providerName,
            };
            const payload = {
              eventData: eData,
              sportId: eData.sportId,
              competitionId: eData.competitionId,
              matchOddsData:
                eventData.market && eventData.market.matchOdds
                  ? eventData.market.matchOdds.find(
                      (mo) => mo.marketName === 'Match Odds'
                    )
                  : null,
            };
            dispatch(fetchEventByCompetitionSuccess(payload));
            // if (eData.sportId === '1' || eData.sportId === '4') {
            for (let mo of eventData.market.matchOdds) {
              if (mo.marketName !== 'Match Odds') {
                const secMOPayload = {
                  eventId: eventData.eventId,
                  marketId: mo.marketId,
                  matchOddsData: mo,
                };
                dispatch(updateSecondaryMatchOdds(secMOPayload));
              }
            }
            // }
            if (eData.sportId === '4') {
              const secMarketsPayload = {
                eventId: eData.eventId,
                bookmakerOddsData:
                  eventData.market && eventData.market.bookmakers
                    ? eventData.market.bookmakers
                    : null,
                enableBookmaker: eventData.market
                  ? eventData.market.enableBookmaker
                  : false,
                sessionOddsData:
                  eventData.market && eventData.market.fancyMarkets
                    ? eventData.market.fancyMarkets
                    : null,
                enableFancy: eventData.market
                  ? eventData.market.enableFancy
                  : false,
              };
              dispatch(updateSecondaryMarkets(secMarketsPayload));
            }
          }
        }
        // if (currentInplayEvents && currentInplayEvents.length > 0) {
        //   for (let ie of currentInplayEvents) {
        //     if (!newList.includes(ie.eventId)) {
        //       const payload = {
        //         sportId: ie.sportId,
        //         competitionId: ie.competitionId,
        //         eventId: ie.eventId,
        //         disableEvent: false,
        //       };
        //       dispatch(disableEventData(payload));
        //     }
        //   }
        // }
      } else {
        console.log('no inplay events fetched');
        // for (let ie of currentInplayEvents) {
        //   const payload = {
        //     sportId: ie.sportId,
        //     competitionId: ie.competitionId,
        //     eventId: ie.eventId,
        //     disableEvent: true,
        //   };
        //   dispatch(disableEventData(payload));
        // }
      }

      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
};

export const updateOddsfromWS = (payload) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: payload,
  };
};

export const disableEventData = (payload) => {
  return {
    type: DISABLE_EVENT_DATA,
    payload: payload,
  };
};

const fetchEventByCompetitionSuccess = (result) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: result,
  };
};

export const clearAllEvents = () => {
  return {
    type: RESET_EVENTS,
    payload: {},
  };
};

export const setExchEvent = (event: SelectedObj) => {
  return {
    type: SET_EXCH_EVENT,
    payload: event,
  };
};

export const fetchEvent = (
  sportId: string,
  competitionId: string,
  eventId: string
) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '' && competitionId !== '' && eventId !== '') {
        const response: AxiosResponse<any> = await API.get(
          `/common-feed/list-event/${sportId}/${competitionId}/${eventId}`
        );

        const eventData = response.data;
        if (eventData.eventId) {
          const eData: EventDTO = {
            inPlay: eventData.inPlay,
            openDate: eventData.openDate,
            sportId: EXCH_SPORTS_MAP[eventData.sportId],
            competitionId: eventData.competitionId,
            competitionName: eventData.competitionName
              ? eventData.competitionName
              : 'Other',
            eventId: eventData.eventId,
            eventName: eventData.eventName,
            marketId: eventData.marketId,
            providerName: eventData.providerName,
          };
          const payload = {
            eventData: eData,
            sportId: eData.sportId,
            competitionId: eData.competitionId,
            matchOddsData:
              eventData.market && eventData.market.matchOdds
                ? eventData.market.matchOdds.find(
                    (mo) => mo.marketName === 'Match Odds'
                  )
                : null,
            onRefresh: true,
          };
          dispatch(fetchEventByCompetitionSuccess(payload));
          // if (eData.sportId === '1' || eData.sportId === '4') {
          for (let mo of eventData.market.matchOdds) {
            if (mo.marketName !== 'Match Odds') {
              const secMOPayload = {
                eventId: eventData.eventId,
                marketId: mo.marketId,
                matchOddsData: mo,
              };
              dispatch(updateSecondaryMatchOdds(secMOPayload));
            }
          }
          // }
          if (eData.sportId === '4') {
            const secMarketsPayload = {
              eventId: eventData.eventId,
              bookmakerOddsData:
                eventData.market && eventData.market.bookmakers
                  ? eventData.market.bookmakers
                  : null,
              enableBookmaker: eventData.market
                ? eventData.market.enableBookmaker
                : false,
              sessionOddsData:
                eventData.market && eventData.market.fancyMarkets
                  ? eventData.market.fancyMarkets
                  : null,
              enableFancy: eventData.market
                ? eventData.market.enableFancy
                : false,
            };
            dispatch(updateSecondaryMarkets(secMarketsPayload));
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const updateSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_SECONDARY_MARKETS,
    payload: payload,
  };
};

export const updateSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};

export const updateBinaryMarkets = (payload) => {
  console.log(payload);
  return {
    type: UPDATE_BINARY_MARKETS,
    payload: payload,
  };
};

export const updateEventScorecard = (payload) => {
  console.log(payload);
  return {
    type: UPDATE_SCORECARD,
    payload: payload,
  };
};
