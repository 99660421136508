import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import './LoginPage.scss';
import webtitleImg from '../../assets/images/brand/title.png';
const modalCloseHandler = () => {};
const LoginPage: React.FC = () => {
  return (
    <div className="login-ctn">
      <div className="title-row">
        <img src={webtitleImg} className="logo" />
      </div>

      <div className="login-card">
        <LoginForm modalCloseHandler={modalCloseHandler} />
      </div>
    </div>
  );
};

export default LoginPage;
