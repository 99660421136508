export const BUTTON_VARIABLES = [
  { label: '100', amount: 100 },
  { label: '500', amount: 500 },
  { label: '1,000', amount: 1000 },
  { label: '5,000', amount: 5000 },
  { label: '10,000', amount: 10000 },
  { label: '50,000', amount: 50000 },
  { label: '1,00,000', amount: 100000 },
  { label: '5,00,000', amount: 500000 },
  { label: '10,00,000', amount: 1000000 },
];
