import rugbyIcon from '../assets/images/sportsbook/icons/rugby.png';
import iceHockeyIcon from '../assets/images/sportsbook/icons/ice-hockey.png';
import badmintonIcon from '../assets/images/sportsbook/icons/badminton.png';
import tableTennisIcon from '../assets/images/sportsbook/icons/table-tennis.png';

export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
];

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
};

type oEvent = {
  label: string;
  img: string;
}
export const OTHER_EVENTS:oEvent[] = [
  {label: 'Rugby', img: rugbyIcon},
  {label: 'Table Tennis', img: tableTennisIcon},
  {label: 'Ice Hockey', img: iceHockeyIcon},
  {label: 'Badminton', img: badmintonIcon},
];