import { AxiosResponse } from 'axios';
import API from '../../api';
import { BsData } from '../../models/BsData';
import { ButtonVariable } from '../../models/ButtonVariables';
import {
  ADD_EXCH_BET,
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_SUCCESS,
  CLEAR_SUCCESS_BETS,
  SET_BUTTON_VARIABLES,
  SET_BETTING_INPROGRESS,
} from './exchBetslipActionTypes';

export const addExchangeBet = (data: BsData) => {
  return async (dispatch: Function) => {
    dispatch(clearExchcngeBets());
    dispatch(addBetHandler(data));
  };
};

const addBetHandler = (data: BsData) => {
  return {
    type: ADD_EXCH_BET,
    payload: data,
  };
};

export const removeExchangeBet = (index: number) => {
  return {
    type: REMOVE_EXCH_BET,
    payload: index,
  };
};

export const setExchangeBetStake = (
  index: number,
  amount: number,
  type: 'ADD' | 'SET'
) => {
  return {
    type: SET_EXCH_BET_STAKE,
    payload: {
      index,
      amount,
      type,
    },
  };
};

export const exchangeBetOddsChange = (index: number, odds: number) => {
  return {
    type: EXCH_BET_ODDS_CHANGE,
    payload: { index, odds },
  };
};

export const clearExchcngeBets = () => {
  return {
    type: CLEAR_EXCHANGE_BETS,
    payload: [],
  };
};

const fetchOpenBetsSuccess = (result) => {
  return {
    type: FETCH_OPEN_BETS_SUCCESS,
    payload: result,
  };
};

export const fetchOpenBets = (eventId: string) => {
  return async (dispatch: Function) => {
    let url = '/user/reports/open-bets-cache';
    if (eventId.toLocaleLowerCase().includes('binary')) {
      url = '/binary/binary-open-bets';
    }
    try {
      API.get(url, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
        params: {
          eventId: eventId,
        },
      })
        .then((response) => {
          dispatch(fetchOpenBetsSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      dispatch(fetchOpenBetsSuccess([]));
    }
  };
};

export const fetchCasinoOpenBets = (gameType: string, mId: string) => {
  return async (dispatch: Function) => {
    try {
      API.post(
        '/indian-casino/open-bets',
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
          params: {
            gameType: gameType,
            mId: mId,
          },
        }
      )
        .then((response) => {
          let payload = [];
          for (const rec of response.data) {
            payload.push({
              betPlacedTime: rec.betPlacedTime,
              stakeAmount: rec.stakeAmount,
              oddValue: rec.odds,
              outcomeDesc: rec.nation,
              betType: 0,
              outcomeId: rec.sId,
            });
          }
          dispatch(fetchOpenBetsSuccess(payload));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      dispatch(fetchOpenBetsSuccess([]));
    }
  };
};

export const clearSuccessBets = (successIndices: number[]) => {
  return {
    type: CLEAR_SUCCESS_BETS,
    payload: successIndices,
  };
};

export const fetchButtonVariables = () => {
  return async (dispatch: Function) => {
    try {
      const response: AxiosResponse<ButtonVariable[]> = await API.get(
        '/user/button-variables',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response) {
        let btnVars = [];
        for (let btnVar of response.data) {
          btnVars.push({
            label: btnVar.label,
            amount: btnVar.amount,
          });
        }
        dispatch(setButtonVariables(btnVars));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setButtonVariables = (buttonVariables: ButtonVariable[]) => {
  return {
    type: SET_BUTTON_VARIABLES,
    payload: buttonVariables,
  };
};

export const setBettingInprogress = (val: boolean) => {
  return {
    type: SET_BETTING_INPROGRESS,
    payload: val,
  };
};
