import { BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://dev-apilam.hypexone.com`,
  REACT_APP_GRAPHQL_API_URL: `https://dev-api.${BRAND_DOMAIN}/graphql/`,
  REACT_APP_WEBSOCKET_URL: `https://dev-api.${BRAND_DOMAIN}/hypex-ws`,
  REACT_APP_AWS_WEBSOCKET_API_URL: `wss://dev-apilam-ws.${BRAND_DOMAIN}`,

  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/dev`,
  BET_PLACEMENT_URL: `https://4lxs2sm5j4.execute-api.eu-west-2.amazonaws.com/dev/`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://demo-api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://demo-apilam.hypexone.com`,
  REACT_APP_GRAPHQL_API_URL: `https://demo-api.${BRAND_DOMAIN}/graphql/`,
  REACT_APP_WEBSOCKET_URL: `https://demo-api.${BRAND_DOMAIN}/hypex-ws`,

  REACT_APP_REST_LAMBDA_API_URL: `https://kjc4f3bczd.execute-api.eu-west-2.amazonaws.com/demo`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://api-auth.hypexone.com`,
  REACT_APP_GRAPHQL_API_URL: `https://api.${BRAND_DOMAIN}/graphql/`,
  REACT_APP_WEBSOCKET_URL: `https://api.hypexone.com/hypex-ws`,
  REACT_APP_REST_LAMBDA_API_URL: `https://zbp5aapli1.execute-api.eu-west-2.amazonaws.com/prod`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
