export const RESET_STATE = 'RESET_STATE';
export const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
export const FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS =
  'FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS';
export const SET_COMPETITION = 'SET_COMPETITION';
export const FETCH_EVENT_BY_COMPETITION_SUCCESS =
  'FETCH_EVENT_BY_COMPETITION_SUCCESS';
export const SET_EXCH_EVENT = 'SET_EXCH_EVENT';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_LOADING = 'SET_LOADING';
export const RESET_EVENTS = 'RESET_EVENTS';
export const UPDATE_SECONDARY_MARKETS = 'UPDATE_SECONDARY_MARKETS';
export const UPDATE_SECONDARY_MATCH_ODDS = 'UPDATE_SECONDARY_MATCH_ODDS';
export const DISABLE_EVENT_DATA = 'DISABLE_EVENT_DATA';
export const UPDATE_SCORECARD = 'UPDATE_SCORECARD';
export const UPDATE_BINARY_MARKETS = 'UPDATE_BINARY_MARKETS';
